/* eslint-disable import/extensions */

// Services Img Imports
import BlockchainTech from '../assets/images/Services/blockchain-tech.webp';
import GenAI from '../assets/images/Services/gen-ai.webp';
import RPA from '../assets/images/Services/rpa.webp';
import WebApp from '../assets/images/Services/web-app.webp';

// Portfolio Img Imports
import SpeechAnalytics from '../assets/images/Portfolio/speech-analytics.png';
import OcrConverter from '../assets/images/Portfolio/ocr-demo-img.png';
import ChatBot from '../assets/images/Portfolio/chatbot.png';
import Streamy from '../assets/images/Portfolio/streamy.webp';
import Eshop from '../assets/images/Portfolio/e-shop-img.png';
import BlockChain from '../assets/images/Portfolio/block_chain_1.png';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import Sasha from '../assets/images/Testimonials/Sasha.jpg';
import Reiner from '../assets/images/Testimonials/Reiner.jpg';
import Kruger from '../assets/images/Testimonials/Kruger.jpg';

// Clients
import AISAL from '../assets/images/Clients/aisal-logo.webp';
import LABA2Z from '../assets/images/Clients/logo-laba2z.webp';
import SPRINGTEST from '../assets/images/Clients/logo-springtest.webp';
import FARSIGHT from '../assets/images/Clients/farsight-logo.webp';
import PANORAMA from '../assets/images/Clients/panorama-dark-logo.webp';
import COPPERWIRE from '../assets/images/Clients/copperwiresystems_logo_4.png';
import HALO from '../assets/images/Clients/halo-logo-red.png';
import haze  from '../assets/images/Clients/haze_media_img.png';
// TeamMembers
import Satpal from '../assets/images/TeamMembers/satpal.jpg';
import GouravSingh from '../assets/images/TeamMembers/gourav-singh.webp';
import NishantSharma from '../assets/images/TeamMembers/nishant-sharma.jpg';
import AnkurPachauri from '../assets/images/TeamMembers/ankur-pachauri.jpg';
import MamtaBhandari from '../assets/images/TeamMembers/mamta-bisht.jpg';
import Aniket from '../assets/images/TeamMembers/Aniketjadon_img_1.jpg';
import Gajendra from '../assets/images/TeamMembers/Gajendra.jpg';
import linkedin from '../assets/images/TeamMembers/linkedin-logo.png';
import Anil from '../assets/images/TeamMembers/Anil_panwar_1.jpg';
import Akshit from '../assets/images/TeamMembers/Akshit Rawat.jpeg';

export const Services = [
  {
    
    title: 'Artificial & Data Intelligence',
    imageUrl: GenAI,
    animation: 'left',
  },
  {
    title: 'Blockchain Technology',
    imageUrl: BlockchainTech,
    animation: 'up',
  },
  {
    title: 'Robotic Process Automation',
    imageUrl: RPA,
    animation: 'down',
  },
  {
    title: 'Web App Development',
    imageUrl: WebApp,
    animation: 'right',
  },
];

export const Portfolios = [
  {
    id: 'asd1293ulkmnbj100',
    title: 'Assets Manager',
    imageUrl: BlockChain,
    type: 'blockchainTech',
    responsibility: [
      'BLOCKCHAIN TECH',
      'NODEJS',
      'DOCKER',
      'HYPERLEDGERFABRIC',
      'JAVASCRIPT',
      'REDIS',

    ],
    credit: 'https://assets-manager.adagen.io/',
    description: 'Hyperledger Fabric can be used to represent assets of any kind on a permissioned decentralized ledger, from fungible tokens to non-fungible tokens, including monetary products, marbles, pineapples, classic cars, fine art, and anything else you can imagine.Fabric can be used to track and update anything about these assets, common examples include asset ownership, size etc.This app will show you all the assets in the ledger and let you can create one also you can delete a few.',
  },
  
  {
    id: 'asd1293ulkmnbj111',
    title: 'Dhruv GenAi Chatbot',
    imageUrl: ChatBot,
    type: 'artificialIntelligence',
    responsibility: [
      'Artificial Intelligence',
      'Generative AI',
    ],
    credit: 'https://adagendemo.in',
    description: 'Dhruv GenAI is an advanced AI-powered chatbot designed to enhance customer interactions and streamline business processes. Built with cutting-edge technology, Dhruv GenAI delivers personalized assistance, quick responses, and efficient problem-solving across various industries. Whether for customer support, information retrieval, or automating tasks, Dhruv GenAI is your intelligent digital assistant, transforming the way businesses engage with their users.',
  },
  
  {
    id: 'asd1293ulkmnbj131',
    title: 'Speech Analytics',
    imageUrl: SpeechAnalytics,
    type: 'roboticProcessAutomation',
    responsibility: [
      'roboticProcessAutomation',
    ],
    credit: 'https://adagen.com/audioDemo.html',
    description: 'Speech analytics, also known as interaction analytics, uses artificial intelligence to process, understand, and analyze human speech. I developed a demo project showcasing how this technology can extract insights from conversations, identifying trends, emotions, and key metrics. It\'s ideal for improving customer interactions, enhancing communication strategies, and automating processes in industries like customer service and sales.',
  },
  {
    id: 'asd1293ulkmnbj141',
    title: 'OCR Coverter',
    imageUrl: OcrConverter,
    type: 'roboticProcessAutomation',
    responsibility: [
      'roboticProcessAutomation',
    ],
    credit: 'https://adagen.com/demo_ocr.html',
    description: 'Optical Character Recognition (OCR) technology analyzes an image\'s structure to detect and recognize text characters, converting them into a machine-readable format. I created a demo project demonstrating OCR\'s capability to extract text from images, making it useful for digitizing documents, automating data entry, and enhancing accessibility by transforming printed or handwritten text into editable formats.',
  },

  {
    id: 'asd1293ulkmnbj90',
    title: 'Streamy React App',
    imageUrl: Streamy,
    type: 'applicationDev',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://streamy-demo.adagendemo.in/',
    description: 'Streamy is a web demo streaming platform built using React for a project. It offers a sleek, user-friendly interface that allows users to stream content seamlessly. With a focus on responsive design and smooth performance, Streamy showcases the versatility of React in delivering high-quality, real-time streaming experiences tailored for modern web applications.',
  },
  {
    id: 'asd1293ulkmnbj91',
    title: 'E-shop React + Express App',
    imageUrl: Eshop,
    type: 'applicationDev',
    responsibility: [
      'React',
      'ExpressJS',
      'PostgreSQL',
    ],
    credit: 'https://www.e-shop.adagendemo.in/',
    description: 'A full-stack e-commerce project from scratch using PostgreSQL, Express, React, and Node (PERN stack). This project demonstrates the complete workflow of an e-commerce platform, from designing product catalogs to managing backend operations like user authentication, order processing, and database management. It showcases essential skills for creating dynamic, responsive web apps with a simple product design as a sample for practical use cases.',
  },
];

export const Advantages = [
  [{
    title: 'Communicative',
    description: 'We communicate our project ideas and progress to make it clear.',
    imageUrl: Communicative,
  },
  {
    title: 'Management',
    description: 'We manage our project properly to make our project done well.',
    imageUrl: Management,
  }],
  [{
    title: 'Collaborative​',
    description: 'Our team are very collaborative to make our project done well.',
    imageUrl: Collaborative,
  },
  {
    title: 'Favorite',
    description: "We've did so many project and all of our client love it.",
    imageUrl: Favorite,
  }],
];

export const Testimonials = [
  {
    id: 1,
    name: 'Sasha Rose',
    company: 'Owner, Surveyor Corps',
    testimoni: 'Thanks for Racxstudio, you guys are the best! Keep up the great work!',
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: 'Kruger Khan',
    company: 'Director, Shultan Oil',
    testimoni: 'I just wanted to let you know that it’s been great working with Racxstudio.',
    imageUrl: Kruger,
  },
  {
    id: 3,
    name: 'Reiner John',
    company: 'CEO, Marley CO',
    testimoni: 'Racxstudio is so great. Thank you so much for a job well done.',
    imageUrl: Reiner,
  },
];

export const Clients = [
  {
    id: 1,
    company: 'Panorama Softwares',
    imageUrl: PANORAMA,
  },
  {
    id: 2,
    company: 'Lab A2Z',
    imageUrl: LABA2Z,
  },
  {
    id: 3,
    company: 'Spring Test Solution',
    imageUrl: SPRINGTEST,
  },
  {
    id: 4,
    company: 'Farsight Technologies',
    imageUrl: FARSIGHT,
  },
  {
    id: 5,
    company: 'Aisal Analytics',
    imageUrl: AISAL,
  },
  {
    id: 6,
    company: 'Copperwire Systems',
    imageUrl: COPPERWIRE,
  },
  {
    id: 7,
    company: 'Halo Media',
    imageUrl: HALO,
  },
  {
    id: 8,
    company: 'haze_media',
    imageUrl: haze,
  },
];

export const TeamMembers = [
  {
    name: 'Satpal',
    position: 'Software Architect',
    imageUrl: Satpal,
    linkedinLogo: linkedin,
    linkedinUrl: 'https://www.linkedin.com/in/satpal-2aa99b160/',
  },
  {
    name: 'Gajendra Rawat',
    position: 'Tech Lead',
    imageUrl: Gajendra,
    linkedinLogo: linkedin,
    linkedinUrl: 'https://www.linkedin.com/in/gajendra-singh-rawat/',
  },
  {
    name: 'Gourav Singh',
    position: 'Full Stack Developer',
    imageUrl: GouravSingh,
    linkedinLogo: linkedin,
    linkedinUrl: 'https://www.linkedin.com/in/gouravsingh541/',
  },
  {
    name: 'Nishant Sharma',
    position: 'Full Stack Developer',
    imageUrl: NishantSharma,
    linkedinLogo: linkedin,
    linkedinUrl: 'https://www.linkedin.com/in/sharmanishant602',
  },
  {
    name: 'Ankur Pachauri',
    position: 'Blockchain Developer',
    imageUrl: AnkurPachauri,
    linkedinLogo: linkedin,
    linkedinUrl: 'https://www.linkedin.com/in/ankur-pachauri-57a1902b3/',
  },
  {
    name: 'Aniket Jadon',
    position: 'UI/UX Developer /devops engineer',
    imageUrl: Aniket,
    linkedinLogo: linkedin,
    linkedinUrl: 'https://www.linkedin.com/in/aniket-jadon-444ab9185',
  },
  {
    name: 'Mamta Bisht',
    position: 'Workspace HR Manager',
    imageUrl: MamtaBhandari,
    linkedinLogo: linkedin,
    linkedinUrl: 'https://www.linkedin.com/in/mamta-bisht-086b07313/',
    
  },
  {
    name: 'Anil Singh',
    position: 'RPA/Automation/AI',
    imageUrl: Anil,
    linkedinLogo: linkedin,
    linkedinUrl: '',
  },
  {
    name: 'Akshit Rawat',
    position: 'Full Stack Developer',
    imageUrl: Akshit,
    linkedinLogo: linkedin,
    linkedinUrl: 'https://www.linkedin.com/in/akshitrawat21/',
  },
  
];
